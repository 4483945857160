<template>
  <div class="container-introduce">
    <div class="container">
      <div
        class="container-1 pt-5 d-flex justify-content-center align-items-center"
      >
        <img
          class="w-25"
          :class="moveShow ? 'slide-in-left' : ''"
          src="@/static/image/about-1-1.png"
          alt=""
        />
        <div
          class="text-center"
          :class="moveShow ? 'tracking-in-expand-fwd-top' : ''"
        >
          <common-title
            title="企业介绍"
            english="Company Introduction"
          ></common-title>
          <div class="Introduction">
            <p>国家高新技术企业</p>
            <p>深圳市专精特新企业</p>
            <p>深圳品牌百强</p>
            <p>携手中科院深圳先进院建立“智能文档处理”联合实验室</p>
            <p>2022中国数字科技年度50强</p>
            <p>2022中国数字科技金融垂直领域的标杆品牌</p>
            <p>企业信用评价AAA级企业</p>
          </div>
        </div>
        <img
          class="w-25"
          :class="moveShow ? 'slide-in-right' : ''"
          src="@/static/image/about-1-2.png"
          alt=""
        />
      </div>
      <div
        class="container-2 pt-5 d-flex justify-content-center align-items-center"
      >
        <div :style="bg_image" :class="moveShow ? 'slide-in-left-txt' : ''">
          <p>
            中壬银兴是一家集设计研发于一体的新一代信息技术服务企业，是国家高新技术、深圳专精特新、深圳百强品牌、中国数字科技50强企业。是企业信贷风控领域的技术领头且率先实现银行流水量化校验技术的企业。核心产品是具有国家专利的金融场景风控系统，应用服务场景包括银行、融担、保理、财会、供应链金融、证券、融资租赁、政府部门、商会协会等。
            中壬银兴目前业务涉及中国大陆多个省份，已成为包含政府部门、国资企业、上市企业等在内的机构、企业的风控技术解决方案服务商，为其提供全业务系统研发、布置服务。
          </p>
          <p>
            2021年，中壬银兴成立蓝莓企服作为 PVE系统的科技成果转化平台，为有融资需求的中小微企业提供测评、金融产品匹配服务。目前PVE债权评测系统评测次数超209万次，有效帮助客户识别与防范风险，提高业务决策效率。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import commonTitle from "@/components/index/common-title.vue";
export default {
  components: {
    commonTitle,
  },
  props: {
    moveShow: {
      type: Boolean,
    },
  },
  data() {
    return {
      bg_image: {
        backgroundImage: "url(" + require("@/static/image/about-1-3.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
      },
    };
  },
};
</script>

<style lang="less" scoped>
.Introduction p {
  font-size: 22px;
  color: #6f76b0;
}
.container-2 {
  p {
    line-height: 1.8;
    color: #6f76b0;
    font-size: 18px;
    text-indent: 2em;
  }
}

.container-introduce {
  background-color: #f6f3ff;
  height: 100%;
}

.tracking-in-expand-fwd-top {
  animation: tracking-in-expand-fwd-top 1s 1s
    cubic-bezier(0.215, 0.61, 0.355, 1) both;
}
@keyframes tracking-in-expand-fwd-top {
  0% {
    letter-spacing: -0.5em;
    transform: translateZ(-700px) translateY(-500px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
.slide-in-left-txt {
  animation: slide-in-left 0.5s 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.slide-in-left {
  animation: slide-in-left 1.5s 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.slide-in-right {
  animation: slide-in-right 1.5s 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

// 左进入
@keyframes slide-in-left {
  0% {
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
// 右进入
@keyframes slide-in-right {
  0% {
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
</style>
